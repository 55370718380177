import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsTutorialOpen, getTutorialStep, getViewedEvent} from "modules/selectors";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS, Step} from "react-joyride";
import {closeTutorial, setActiveStep, setIsOpenMobileNavigation} from "modules/actions";
import {TourTooltip} from "./TourTooltip";
import {TooltipContent} from "./TooltipContent";
import {useMedia} from "react-use";

const cartTarget = ".tutorial-cart";

export const Tutorial: React.FC = () => {
	const dispatch = useDispatch();
	const [isHideTutorial, setIsHideTutorial] = useState(false);
	const isTutorialOpen = useSelector(getIsTutorialOpen);
	const isOpen = isTutorialOpen && !isHideTutorial;
	const step = useSelector(getTutorialStep);
	const nextEvent = useSelector(getViewedEvent);
	const isMobile = useMedia("(max-width: 768px)");

	const steps: Step[] = [
		{
			target: cartTarget,
			content: (
				<TooltipContent
					title="Build your Team"
					message="Pick six golfers who are in this week’s field. Select one player to be your captain. Their points will be doubled."
					mobileMessage="Add your selected golfer here"
				/>
			),
			floaterProps: {
				placement: "bottom",
			},
			placement: "bottom",
			disableBeacon: true,
			disableScrolling: true,
		},
		{
			target: cartTarget,
			content: (
				<TooltipContent
					title="Earn Points"
					message="Golfers will score points based on a number of statistics, including birdies, pars, low rounds as well as their finishing position in the tournament."
				/>
			),
			placement: "bottom",
			disableBeacon: true,
			floaterProps: {
				target: ".tutorial-country",
			},
			offset: 0,
		},
		{
			target: cartTarget,
			content: (
				<TooltipContent
					title="Selection Cap"
					message="You can make unlimited transfers to ensure that your entire team is in the field each week.  But be careful...you can select each DP World Tour member five times in the season and each non-DP World Tour member three times!"
				/>
			),
			placement: "bottom",
			disableBeacon: true,
			floaterProps: {
				target: ".tutorial-selection",
			},
			offset: 0,
		},
		{
			target: ".team-players",
			content: (
				<TooltipContent
					title="Forget to Pick?"
					message="If you forget to pick a team, you will automatically be assigned six random players (including a captain) at the start of a tournament, to ensure you don’t miss out on points."
				/>
			),
			placement: "bottom",
			disableBeacon: true,
			offset: 0,
		},
		{
			target: isMobile ? '.mobile-nav a[href="/leagues"]' : 'a[href="/leagues"]',
			content: (
				<TooltipContent
					title="Join/Create Leagues"
					message="Challenge your family, friends and colleagues by creating and joining different leagues."
				/>
			),
			placement: "bottom",
			disableBeacon: true,
			offset: 0,
			disableScrolling: true,
		},
		{
			target: "body",
			content: (
				<TooltipContent
					title="You’re ready!"
					message={`What are you waiting for? Pick your team ${
						nextEvent ? "for the " + nextEvent.name : ""
					} event now!`}
				/>
			),
			placement: "center",
			disableBeacon: true,
		},
	];

	const nextStepHandler = (action: string, index: number) => {
		const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
		dispatch(setIsOpenMobileNavigation(nextIndex === 4 && isMobile));

		if(nextIndex === 3 && isMobile) {
			setTimeout(() => {
				window.scrollTo({
					top: 100,
					behavior: "smooth",
				});
			}, 800)
		}

		if (nextIndex === 4 && isMobile) {
			dispatch(setActiveStep(nextIndex));

			// step with navigation
			setIsHideTutorial(true);

			setTimeout(() => {
				// wait for the mobile navigation to open
				setIsHideTutorial(false);
				dispatch(setActiveStep(nextIndex));
			}, 700);
		} else {
			dispatch(setActiveStep(nextIndex));
		}
	};

	const handleJoyrideCallback = (data: CallBackProps) => {
		const {action, index, status, type} = data;

		if ((EVENTS.STEP_AFTER as string) === type) {
			nextStepHandler(action, index);
		} else if ((EVENTS.TARGET_NOT_FOUND as string) === type) {
			if (index !== 4) {
				// navigation
				dispatch(setActiveStep(index + (action === ACTIONS.PREV ? -1 : 1)));
			}
		} else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
			dispatch(closeTutorial());
		}
	};

	return (
		<Fragment>
			{/*
				Disable "Joyride' cannot be used as a JSX component." error,
				as it's something wrong with @types/react version.
				More details here https://github.com/facebook/react/issues/24304
				It doesn't affect work, but breaks type checking for some elements.
			*/}
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Joyride
				stepIndex={step}
				scrollToFirstStep
				callback={handleJoyrideCallback}
				steps={steps}
				run={isOpen}
				tooltipComponent={TourTooltip}
				continuous={true}
			/>
		</Fragment>
	);
};
