import React, {useCallback, useEffect, useState} from 'react';
import logo from "assets/img/logo-dark-fantasy.svg";
import {ModalWindow} from "components/Modals/common";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import {GAME_SEASON} from "modules";
import {userAcceptViewWelcomeModal} from "modules/actions";

const Wrapper = styled.div`
  img {
    max-width: 240px;
    width: 100%;
    max-height: 110px;
    height: auto;
  }
`

export const SeasonToggleModal: React.FC = () => {
	const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector(getUser)!;

    const close = useCallback(() => {
		console.log("close");
		setIsOpen(false);
    }, [setIsOpen])

    useEffect(() => {
        if (user && !user.isWelcomeModalViewed) {
            setIsOpen(true);
			dispatch(userAcceptViewWelcomeModal());
        }
    }, [dispatch, setIsOpen, user])

    if (!isOpen || !user) {
        return null;
    }

    return (
        <Wrapper>
            <ModalWindow
                title={`Welcome to Fantasy DP World Tour ${GAME_SEASON}!`}
                message={`The ${GAME_SEASON-1} season is now complete and we hope you enjoyed playing. If you'd like to review how you did each week, including viewing your teams, scores and leagues, you can see this at any point in the season using the season toggle in the top right and changing to ${GAME_SEASON-1}.`}
                onClose={close}
                iconSrc={logo}
            />
        </Wrapper>);
}
