import {createAction} from "redux-act";
import {ApiError} from "modules/utils";

export interface ITeam {
	id: number;
	name: string;
	lineup: number[];
	captain: number | null;
	start_event_id?: number;
	withdrawn: number[] | null;
	points: number | null;
	event_rank: number | null;
	overall_rank: number | null;
	overall_points: number | null;
	scoreflow: Record<number, number>;
	selections: Record<number, number>;
	createdAt: string;
}

export interface IRequestedTeamPayload {
	team: ITeam;
	eventID: number;
	hasUnsavedChanges?: boolean;
}

export const requestMyTeam = createAction<number>();
export const requestMyTeamSuccess = createAction<IRequestedTeamPayload>();
export const autofillSuccess = createAction<IRequestedTeamPayload>();
export const requestMyTeamFailed = createAction<ApiError>();

export interface IRequestedRivalTeamPayload {
	userID: number;
	eventID: number;
}

export const requestRivalTeam = createAction<IRequestedRivalTeamPayload>();
export const requestRivalTeamSuccess = createAction<IRequestedTeamPayload>();
export const requestRivalTeamFailed = createAction<ApiError>();

export const clearTeam = createAction<number>();

export const autoFillTeam = createAction<number>();
export const autoFillTeamSuccess = createAction<IRequestedTeamPayload>();
export const autoFillTeamFailed = createAction<ApiError>();

export const saveTeam = createAction<number>();
export const saveTeamFromLocalStorage = createAction<number>();
export const saveTeamSuccess = createAction<IRequestedTeamPayload>();
export const saveTeamFailed = createAction<ApiError>();
export const hideSaveTeamSuccessModal = createAction();

interface ITeamActionPayload {
	eventID: number;
	playerID: number;
}

export const setCaptain = createAction<ITeamActionPayload>();
export const addPlayerToTeam = createAction<ITeamActionPayload>();
export const removePlayerFromTeam = createAction<ITeamActionPayload>();

export const showPlayerModal = createAction<number>();
export const hidePlayerModal = createAction();

export const clearTeamsByID = createAction();
export const clearWithdrawn = createAction<number>();
