import React, {Fragment, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useState} from "react";
import styled from "styled-components";
import {Container as BaseContainer, ExternalLink, GameLogoLink} from "components/Common";
import logo from "assets/img/fantlogo-light.svg";
import {NavLink, Route, Routes} from "react-router-dom";
import {BaseButton, ButtonPrimary} from "components/Buttons";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as FacebookIcon} from "assets/img/icon-facebook.svg";
import TwitterIcon from "assets/img/twitter-x.png";
import ShareIcon from "@mui/icons-material/Share";
import {
	getIsCurrentSeason,
	getIsOpenMobileNavigation,
	getIsTutorialOpen,
	getSeason, getUser,
	isLoggedIn,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {useLocation} from "react-router";
import {share} from "modules/utils";
import {ShareType, SocialNetwork} from "modules";
import bgNav from "assets/img/bg-nav.svg";
import bgNavMobile from "assets/img/bg-nav-mobile.svg";
import bgNavFill from "assets/img/bg-nav-fill.svg";
import {SeasonToggle} from "components/SeasonToggle";
import {useMedia} from "react-use";
import {openTutorial, showAuthenticationModal} from "modules/actions";
import {LoginGigyaModal} from "components/Modals";

const mobileQuery = "(max-width: 768px)";

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 4;
  background: url(${bgNav}) no-repeat center center / auto 100%,
  url(${bgNavFill}) no-repeat center center / 100% 100%;

  @media (max-width: 1240px) {
    background: url(${bgNav}) no-repeat -120px center / auto 100%;
  }

  @media (max-width: 768px) {
    background: url(${bgNavMobile}) no-repeat center center / 100% 100%;
  }
`;

const LogoLink = styled(GameLogoLink)`
  margin: 18px 55px 18px 0;

  @media (max-width: 960px) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const Container = styled(BaseContainer)`
  display: flex;
  justify-content: flex-start;
`;

const DesktopNav = styled.nav`
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	&.left {
		justify-content: flex-start;
	}
	
	@media (max-width: 768px) {
		.desktop-nav {
			display: none;
		}
	}
`;

const DesktopNavSubNav = styled.nav`
  background: #3230be;
  flex: 1;
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }

  ${Container} {
    height: 52px;
    justify-content: flex-start;
    padding-left: 196px;

    @media (max-width: 840px) {
      padding-left: 186px;
    }
  }
`;

const MenuWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 840px) {
		flex-direction: column;
		
		&.authorize-nav {
			flex-direction: row;
			
			a {
				font-size: 15px;
				
				&:first-child {
					margin-right: 10px;
				}
			}
		}
	}
`;

const NavItem = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  height: 100%;
  font-size: 18px;
  transition: color 0.3s;
  position: relative;

  &:last-child {
    margin-right: 0;
    margin-left: auto;
  }

  &.active,
  &:hover {
    position: relative;
    color: #fff;
  }

  &.active::after {
    content: "";
    display: block;
    width: 30%;
    height: 6px;
    background: #00e68c;
    clip-path: polygon(10% 0, 90% 0, 100% 100%, 0 100%);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
  }

  @media (max-width: 880px) {
    font-size: 16px;
	  margin: 15px 0;
  }

  @media (max-width: 768px) {
    &.active::after {
      display: none;
    }

    &:last-child {
      margin: auto;
    }
  }
`;

const NavItemTutorial = styled(NavItem)`
	&.active::after {
		display: none;
	}
`;

const LoginNav = styled(NavItem)`
	background: #1E1450;
	font-size: 16px;
	height: 44px;
	border-radius: 22px;
	margin-right: 20px;

	@media (max-width: 880px) {
		margin-right: 0;
	}
`;

const NavSubItem = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  height: 100%;
  font-size: 18px;
  transition: border-bottom-color 0.3s;
  border-bottom: 2px solid transparent;

  &.active,
  &:hover {
    border-bottom-color: #00e68c;
  }

  @media (max-width: 880px) {
    font-size: 15px;
  }
`;

const HamburgerButton = styled(BaseButton)`
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 2px solid currentColor;
  display: none;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileMenu = styled.div`
  background: linear-gradient(107.62deg, #3a0ec6 0%, #03da8f 100%);
  display: none;
  height: 100%;
  width: 100%;
  padding-top: 68px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 3;
  right: 100%;
  top: 0;
  transition: right 0.3s ease-in-out;

  @media (max-width: 768px) {
    display: block;
  }

  &.opened {
    right: 0;
  }
`;

const MobileMenuInner = styled.div`
  padding: 40px 20px 0;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: 100%;
`;

const MobileMenuScroll = styled.div`
  min-height: 100%;
  overflow: auto;
  height: 100%;
`;

const MediaBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: column;
	gap: 15px;
	margin: auto auto 20px;
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SocialExternalLink = styled(ExternalLink)`
  color: currentColor;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 2px solid currentColor;
  border-radius: 100%;
  transition: opacity 0.5s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .MuiSvgIcon-root {
    font-size: 19px;
  }
  
  img {
    width: 14px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

interface IShareButtonProps {
	isHidden?: boolean;
}

const ShareButton = styled(BaseButton)<IShareButtonProps>`
	color: white;
	margin: 0 10px 0 auto;
	display: none;
	visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
	align-self: center;
	
	@media (max-width: 800px) {
		display: ${(props) => (props.isHidden ? "none" : "block")};
		width: 32px;
		height: 32px;
		margin: 20px auto 20px auto;
	
		svg {
			width: 32px;
			height: 32px;
		}
	}
`;

const SubNavWrapper: React.FC<PropsWithChildren<unknown>> = ({children}) => (
	<DesktopNavSubNav>
		<Container>{children}</Container>
	</DesktopNavSubNav>
);

const MobileNav = styled.nav`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 26px;
  margin-bottom: 40px;
`;

const LogoutButton = styled(ButtonPrimary)`
	background: #1E1450;
	font-size: 16px;
	height: 44px;
	border-radius: 22px;
	margin-right: 20px;
	border-color: #1E1450;

	@media (max-width: 880px) {
		width: 100%;
		margin-right: 0;
	}
`;

const shareMessage =
	"I’m playing Fantasy DP World Tour - come and take me on by picking six golfers each week and compete for some great prizes!";

const NavLinksList: React.FC<{toggleMobileMenuState: () => void}> = () => {
	const dispatch = useDispatch();
	const isAuthorized = useSelector(isLoggedIn);
	const isMobile = useMedia(mobileQuery);
	const isSeason = useSelector(getIsCurrentSeason);
	const isTutorialOpen = useSelector(getIsTutorialOpen);
	const user = useSelector(getUser);

	// TODO: This is fast copy from landing. Need to refactoring.
	const [isLoginVisible, showLoginModal] = useState(false);

	useEffect(() => {
		if(user) {
			showLoginModal(false);
		}
	}, [user]);

	const handleOpenTutorial = useCallback(() => {
		dispatch(openTutorial());
	}, [dispatch]);

	const handleFBShare = useCallback(() => {
		shareFB();
	}, []);

	const handleTWShare = useCallback(() => {
		shareTW();
	}, []);

	const handleShowLoginModal = useCallback((event: React.SyntheticEvent) => {
		event.preventDefault();
		showLoginModal(true);
	}, []);

	const closeLogin = useCallback(() => {
		showLoginModal(false);
	}, []);

	const handleShowRegistrationModal = useCallback((event: React.SyntheticEvent) => {
		event.preventDefault();
		dispatch(showAuthenticationModal());
	}, [dispatch]);

	return (
		<Fragment>
			<MenuWrapper className="desktop-nav">
				<NavItem to="/team" end>
					My Team
				</NavItem>
				<Exist when={isAuthorized || isTutorialOpen}>
					<NavItem to="/leagues">Leagues</NavItem>
				</Exist>
				<Exist when={isAuthorized}>
					<NavItem to="/leaderboard">Standings</NavItem>
				</Exist>
				<Exist when={isSeason}>
					<NavItem to="/prizes">Prizes</NavItem>
					<NavItem to="/help">Help</NavItem>
				</Exist>
				<NavItemTutorial to="/team"  onClick={handleOpenTutorial}>Tutorial</NavItemTutorial>
				<Exist when={isAuthorized}>
					<NavItem to="/account">Account</NavItem>
				</Exist>
			</MenuWrapper>

			<MenuWrapper className="authorize-nav">
				<Exist when={isAuthorized}>
					<Exist when={!isMobile}>
						<SeasonToggle/>
					</Exist>
				</Exist>
				<Exist when={!isAuthorized}>
					<LoginNav to="/" onClick={handleShowLoginModal}>Log In</LoginNav>
					<LoginNav to="/" onClick={handleShowRegistrationModal}>Sign Up</LoginNav>
				</Exist>
				<SocialLinks>
					<SocialExternalLink onClick={handleFBShare}>
						<FacebookIcon/>
					</SocialExternalLink>
					<SocialExternalLink onClick={handleTWShare}>
						<img src={TwitterIcon} alt="twitter-x"/>
					</SocialExternalLink>
				</SocialLinks>
			</MenuWrapper>
			<Exist when={isLoginVisible}>
				<LoginGigyaModal onClose={closeLogin} />
			</Exist>
		</Fragment>
	);
};

function shareFB() {
	share({
		socialNetwork: SocialNetwork.Facebook,
		type: ShareType.General,
		message: shareMessage,
	});
}


function shareTW() {
	share({
		socialNetwork: SocialNetwork.Twitter,
		type: ShareType.General,
		message: shareMessage,
	});
}

const OffSeasonNavLinks: React.FC = () => {
	const isAuthorized = useSelector(isLoggedIn);
	const isMobile = useMedia(mobileQuery);

	const handleFBShare = useCallback(() => {
		shareFB();
	}, []);

	const handleTWShare = useCallback(() => {
		shareTW();
	}, []);

	return (
		<Fragment>
			<MenuWrapper className="desktop-nav">
				<Exist when={isAuthorized}>
					<NavItem to="/team" end>
						My Team
					</NavItem>
					<NavItem to="/leagues">Leagues</NavItem>
					<NavItem to="/leaderboard">Standings</NavItem>
				</Exist>
			</MenuWrapper>
			<MenuWrapper className="authorize-nav">
				<Exist when={isAuthorized}>
					<Exist when={!isMobile}>
						<SeasonToggle/>
					</Exist>
				</Exist>
				<SocialLinks>
					<SocialExternalLink onClick={handleFBShare}>
						<FacebookIcon/>
					</SocialExternalLink>
					<SocialExternalLink onClick={handleTWShare}>
						<img src={TwitterIcon} alt="twitter-x"/>
					</SocialExternalLink>
				</SocialLinks>
			</MenuWrapper>
			<Exist when={!isAuthorized}>
				<NavItem to="/">Log in</NavItem>
			</Exist>
		</Fragment>
	);
};

const LeaguesSubNavList: React.FC = () => {
	const isSeason = useSelector(getIsCurrentSeason);

	if (!isSeason) {
		return null;
	}

	return (
		<SubNavWrapper>
			<NavSubItem end to="/leagues">
				My Leagues
			</NavSubItem>
			<NavSubItem to="/leagues/create">Create League</NavSubItem>
			<NavSubItem to="/leagues/join">Join League</NavSubItem>
		</SubNavWrapper>
	)
}

const ContentWrapper = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Header: React.FC = () => {
	const location = useLocation();
	const isAuthorized = useSelector(isLoggedIn);
	const isOpenMobileNavigation = useSelector(getIsOpenMobileNavigation);
	const isSeason = useSelector(getIsCurrentSeason);
	const season = useSelector(getSeason);

	const isSupportMobileShare = "share" in navigator;
	const isMobile = useMedia(mobileQuery);
	// const navClass = isSeason ? "" : "left";

	const [isMenuOpened, setMobileMenuOpenState] = useState(false);
	const mobileMenuClassName = isMenuOpened ? "opened" : "";

	const toggleMobileMenuState = useCallback(() => {
		setMobileMenuOpenState(!isMenuOpened);
	}, [isMenuOpened]);

	const onLogout = () => window.gigya?.accounts.logout();

	const handleShare = useCallback(() => {
		share({
			socialNetwork: SocialNetwork.Mobile,
			type: ShareType.General,
			message: shareMessage,
		});
	}, []);

	useLayoutEffect(() => {
		document.body.style.overflow = isMenuOpened ? "hidden" : "initial";
	}, [isMenuOpened]);

	useEffect(() => {
		setMobileMenuOpenState(false);
	}, [location]);

	useEffect(() => {
		setMobileMenuOpenState(false);
	}, [season]);

	useEffect(() => {
		setMobileMenuOpenState(isOpenMobileNavigation);
	}, [isOpenMobileNavigation]);

	return (
		<header>
			<HeaderWrapper>
				<Container>
					<LogoLink>
						<img src={logo} alt="DP World Tour Fantasy"/>
					</LogoLink>
					<DesktopNav>
						{isSeason ? <NavLinksList toggleMobileMenuState={toggleMobileMenuState}/> : <OffSeasonNavLinks/>}
					</DesktopNav>
					<ContentWrapper>
						<Exist when={isMobile}>
							<SeasonToggle/>
						</Exist>
						<HamburgerButton onClick={toggleMobileMenuState}>
							{isMenuOpened ? <CloseIcon/> : <MenuIcon/>}
						</HamburgerButton>
					</ContentWrapper>

				</Container>
			</HeaderWrapper>
			<MobileMenu className={mobileMenuClassName}>
				<MobileMenuScroll>
					<MobileMenuInner>
						<MobileNav className="mobile-nav">
							<NavLinksList toggleMobileMenuState={toggleMobileMenuState}/>
						</MobileNav>
						<MediaBox>
							<GameLogoLink>
								<img src={logo} alt="DP World Tour Fantasy"/>
							</GameLogoLink>
							<ShareButton isHidden={!isSupportMobileShare} onClick={handleShare}>
								<ShareIcon/>
							</ShareButton>
							<Exist when={isAuthorized}>
								<LogoutButton onClick={onLogout}>Log out</LogoutButton>
							</Exist>
						</MediaBox>
					</MobileMenuInner>
				</MobileMenuScroll>
			</MobileMenu>
			<Routes>
				<Route path="leagues/*" element={<LeaguesSubNavList/>}/>
			</Routes>
		</header>
	);
};
