import {createReducer} from "redux-act";
import {
	askUserToCompleteRegistration,
	clearResetPasswordState,
	clearUserRegistrationData,
	type IUserLoginPayload,
	markUserInfoAsUpdated,
	requestCurrentUser,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	requestResetPassword,
	requestResetPasswordFailed,
	requestResetPasswordSuccess,
	resetPassword,
	resetPasswordFailed,
	resetPasswordSuccess,
	unMarkUserInfoAsUpdated,
	userAcceptTermsSuccess,
	userClearError,
	userInfoUpdate,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLogout,
	userLogoutFailed,
	userLogoutSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	userUpdate,
	userUpdateFailed,
	userUpdateSuccess,
} from "modules/actions/user";
import {RequestState} from "modules/enums";
import {ApiError} from "modules/utils";

export interface IRegisteredGameEntity {
	season: number,
	terms: boolean
}

export interface IRegisteredGame {
	game: string;
	seasons: IRegisteredGameEntity[];
}

export interface IUser {
	id: number;
	email: string;
	username: string;
	isNotificationsEnabled: boolean;
	createdAt: string;
	firstname: string;
	lastname: string;
	opt_in: boolean;
	autopick: boolean;
	country: string | null;
	registeredGames: IRegisteredGame[];
	isNextSeasonTermsModalViewed: boolean;
	isWelcomeModalViewed: boolean;
}

export interface IUserReducer {
	user?: IUser;
	error?: string;
	requestState: RequestState;
	sessionCheckedState: RequestState;
	logoutWasCalled: boolean;
	passwordUpdated: boolean;
	userInfoUpdated: boolean;
	passwordReset: {
		requestState: RequestState;
		error?: string;
	};
	isAdditionalDataRequired: boolean;
	gigya?: IUserLoginPayload;
}

const defaultState: IUserReducer = {
	requestState: RequestState.IDLE,
	sessionCheckedState: RequestState.IDLE,
	logoutWasCalled: false,
	passwordUpdated: false,
	userInfoUpdated: false,
	isAdditionalDataRequired: false,
	passwordReset: {
		requestState: RequestState.IDLE,
	},
};

const onError = (state: IUserReducer, error: ApiError) => ({
	...state,
	error: error.message,
	requestState: RequestState.Received,
});

const onAuthRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
	logoutWasCalled: false,
});

const onReceiveUser = (state: IUserReducer, user: IUser) => ({
	...state,
	user,
	error: undefined,
	requestState: RequestState.Received,
	isAdditionalDataRequired: false,
	gigya: undefined,
});

const onResetPasswordFailed = (state: IUserReducer, error: ApiError) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Received,
		error: error.message,
	},
});

const onResetPasswordSuccess = (state: IUserReducer) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Received,
	},
});

const onResetPasswordRequested = (state: IUserReducer) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Requested,
	},
});

const onPerformRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

export const user = createReducer<IUserReducer>({}, defaultState)
	/**
	 * Login
	 */
	.on(userLogin, onAuthRequest)
	.on(userLoginSuccess, (state, user) => ({
		...state,
		user,
		isAuthorized: true,
		error: undefined,
		requestState: RequestState.Received,
	}))
	.on(askUserToCompleteRegistration, (state, gigya) => ({
		...state,
		gigya,
		isAdditionalDataRequired: true,
		sessionCheckedState: RequestState.Received,
		requestState: RequestState.IDLE,
	}))
	.on(clearUserRegistrationData, (state) => ({
		...state,
		gigya: undefined,
		isAdditionalDataRequired: false,
	}))
	.on(userLoginFailed, onError)
	/**
	 * Update
	 */
	.on(userUpdate, onPerformRequest)
	.on(userUpdateSuccess, onReceiveUser)
	.on(userAcceptTermsSuccess, onReceiveUser)
	.on(userUpdateFailed, onError)
	.on(userInfoUpdate, onPerformRequest)
	.on(markUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: true,
	}))
	.on(unMarkUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: false,
	}))
	/**
	 * Logout
	 */
	.on(userLogout, (state) => ({
		...state,
		requestState: RequestState.Requested,
		logoutWasCalled: true,
	}))
	.on(userLogoutSuccess, (state) => ({
		...state,
		user: undefined,
		requestState: RequestState.Received,
	}))
	.on(userLogoutFailed, onError)
	/**
	 * Register
	 */
	.on(userRegister, onAuthRequest)
	.on(userRegisterSuccess, onReceiveUser)
	.on(userRegisterFailed, onError)
	/**
	 * Get current user
	 */
	.on(requestCurrentUser, (state) => ({
		...state,
		sessionCheckedState: RequestState.Requested,
		logoutWasCalled: false,
	}))
	.on(requestCurrentUserSuccess, (state, user) => ({
		...state,
		user,
		error: undefined,
		sessionCheckedState: RequestState.Received,
	}))
	.on(requestCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))
	/**
	 * Password reset
	 */
	.on(requestResetPassword, onResetPasswordRequested)
	.on(resetPassword, onResetPasswordRequested)
	.on(requestResetPasswordSuccess, onResetPasswordSuccess)
	.on(resetPasswordSuccess, onResetPasswordSuccess)
	.on(requestResetPasswordFailed, onResetPasswordFailed)
	.on(resetPasswordFailed, onResetPasswordFailed)
	.on(clearResetPasswordState, (state, _error) => ({
		...state,
		passwordReset: defaultState.passwordReset,
	}))
	/**
	 * Common
	 */
	.on(userClearError, (state) => ({
		...state,
		error: undefined,
	}));
