import {createReducer} from "redux-act";
import {
	addPlayerToTeam, autofillSuccess,
	autoFillTeam,
	autoFillTeamFailed,
	autoFillTeamSuccess,
	clearTeam,
	clearTeamsByID,
	clearWithdrawn,
	hidePlayerModal,
	hideSaveTeamSuccessModal,
	IRequestedTeamPayload,
	ITeam,
	removePlayerFromTeam,
	requestMyTeam, requestMyTeamFailed,
	requestMyTeamSuccess,
	requestRivalTeamSuccess,
	saveTeam,
	saveTeamFailed,
	saveTeamSuccess,
	setCaptain,
	showPlayerModal,
} from "modules/actions";
import {RequestState} from "modules/enums";

interface IReducer {
	byEvent: Record<number, ITeam>;
	requestState: RequestState;
	hasUnsavedChanges: boolean;
	showDetailsForPlayerID: null | number;
	showSuccessSaveModal: boolean;
	lastPlayerIDCard: string;
}

const defaultState: IReducer = {
	byEvent: {},
	requestState: RequestState.IDLE,
	hasUnsavedChanges: false,
	showDetailsForPlayerID: null,
	showSuccessSaveModal: false,
	lastPlayerIDCard: "",
};

const defaultTeam = {
	id: 0,
	name: "",
	lineup: [],
	captain: null,
	start_event_id: 0,
	points: null,
	scoreflow: {},
	selections: {},
	createdAt: "",
};

const onSuccessTeamSave = (state: IReducer) => ({
	...state,
	requestState: RequestState.Received,
	showSuccessSaveModal: true,
});

const onFailedTeamSave = (state: IReducer) => ({
	...state,
	requestState: RequestState.Received,
});

const onSuccessReceiveTeam = (state: IReducer, {team, eventID}: IRequestedTeamPayload) => ({
	...state,
	hasUnsavedChanges: false,
	requestState: RequestState.Received,
	byEvent: {
		...state.byEvent,
		[eventID]: {
			...defaultTeam,
			...team,
		},
	},
});

const onAutofillSuccess = (state: IReducer, {team, eventID}: IRequestedTeamPayload) => ({
	...state,
	hasUnsavedChanges: true,
	requestState: RequestState.Received,
	byEvent: {
		...state.byEvent,
		[eventID]: {
			...defaultTeam,
			...team,
		},
	},
});

export const team = createReducer<typeof defaultState>({}, defaultState)
	.on(saveTeam, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(autoFillTeam, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(saveTeamSuccess, onSuccessTeamSave)
	.on(saveTeamFailed, onFailedTeamSave)
	.on(autoFillTeamSuccess, onSuccessTeamSave)
	.on(autoFillTeamFailed, onFailedTeamSave)
	.on(hideSaveTeamSuccessModal, (state) => ({
		...state,
		requestState: RequestState.Received,
		showSuccessSaveModal: false,
	}))
	.on(requestMyTeam, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(requestMyTeamSuccess, onSuccessReceiveTeam)
	.on(autofillSuccess, onAutofillSuccess)
	.on(requestMyTeamFailed, (state) => ({
		...state,
		requestState: RequestState.IDLE,
	}))
	.on(requestRivalTeamSuccess, onSuccessReceiveTeam)
	.on(clearTeam, (state, eventID) => ({
		...state,
		hasUnsavedChanges: true,
		requestState: RequestState.IDLE,
		byEvent: {
			...state.byEvent,
			[eventID]: {
				...state.byEvent?.[eventID],
				captain: null,
				lineup: [],
			},
		},
	}))
	.on(clearWithdrawn, (state, eventID) => ({
		...state,
		byEvent: {
			...state.byEvent,
			[eventID]: {
				...state.byEvent?.[eventID],
				withdrawn: null,
			},
		},
	}))
	.on(addPlayerToTeam, (state, {eventID, playerID}) => {
		const team = state.byEvent?.[eventID];

		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			lastPlayerIDCard: `player-card-id-${playerID}`,
			byEvent: {
				...state.byEvent,
				[eventID]: {
					...team,
					lineup: [...(team?.lineup || []), playerID],
				},
			},
		};
	})
	.on(removePlayerFromTeam, (state, {eventID, playerID}) => {
		const team = state.byEvent?.[eventID];
		const captain = team?.captain;

		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			byEvent: {
				...state.byEvent,
				[eventID]: {
					...team,
					lineup: team?.lineup.filter((id) => id !== playerID) || [],
					captain: captain === playerID ? null : captain,
				},
			},
		};
	})
	.on(setCaptain, (state, {eventID, playerID}) => {
		const team = state.byEvent?.[eventID];

		return {
			...state,
			hasUnsavedChanges: true,
			requestState: RequestState.IDLE,
			byEvent: {
				...state.byEvent,
				[eventID]: {
					...team,
					captain: playerID,
				},
			},
		};
	})
	.on(showPlayerModal, (state, playerID) => ({
		...state,
		showDetailsForPlayerID: playerID,
	}))
	.on(hidePlayerModal, (state) => ({
		...state,
		showDetailsForPlayerID: null,
	}))
	.on(clearTeamsByID, (state) => ({
		...state,
		byEvent: defaultState.byEvent,
	}));
