import {createAction} from "redux-act";
import {IUser} from "modules/reducers";
import {ApiError} from "modules/utils";

export interface IUserLoginPayload {
	uid: string; // Gigya ID
	uidSignature: string;
	signatureTimestamp: string;
	country?: string;
}

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<ApiError>();

export const askUserToCompleteRegistration = createAction<IUserLoginPayload>();
export const clearUserRegistrationData = createAction();

export interface IUserUpdatePayload {
	username?: string;
	opt_in?: boolean;
	isNotificationsEnabled?: boolean;
	autopick?: boolean;
	isNextSeasonTermsModalViewed?: boolean;
	isWelcomeModalViewed?: boolean;
}

export const userUpdate = createAction<IUserUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<ApiError>();

type IUserInfoUpdatePayload = Pick<IUserUpdatePayload, "username" | "opt_in" | "isNextSeasonTermsModalViewed" | "isWelcomeModalViewed">;

export const userInfoUpdate = createAction<IUserInfoUpdatePayload>();
export const markUserInfoAsUpdated = createAction();
export const unMarkUserInfoAsUpdated = createAction();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<ApiError>();

export interface IUserRegisterPayload {
	username: string;
	opt_in: boolean;
}

export const userRegister = createAction<IUserRegisterPayload>();
export const userRegisterSuccess = createAction<IUser>();
export const userRegisterFailed = createAction<ApiError>();

export interface IAcceptTermsPayload {
	terms: boolean;
	season?: string;
}

export const showAcceptTermsModal = createAction();
export const userAcceptTerms = createAction<IAcceptTermsPayload>();
export const userAcceptTermsSuccess = createAction<IUser>();
export const userAcceptTermsFailed = createAction<ApiError>();

export const userAcceptNextSeasonTerms = createAction();
export const userAcceptNextSeasonTermsFailed = createAction<ApiError>();

export const userAcceptViewWelcomeModal = createAction();
export const userAcceptViewWelcomeModalFailed = createAction<ApiError>();

export const userClearError = createAction();

export const requestCurrentUser = createAction();
export const requestCurrentUserSuccess = createAction<IUser>();
export const requestCurrentUserFailed = createAction();

export interface IRequestResetPasswordPayload {
	email: string;
}

export const requestResetPassword = createAction<IRequestResetPasswordPayload>();
export const requestResetPasswordSuccess = createAction();
export const requestResetPasswordFailed = createAction<ApiError>();
export const clearResetPasswordState = createAction();

export interface IResetPasswordPayload {
	password: string;
	token: string;
}

export const resetPassword = createAction<IResetPasswordPayload>();
export const resetPasswordSuccess = createAction();
export const resetPasswordFailed = createAction<ApiError>();
